import { useCallback } from 'react';
// import { useSelector } from 'react-redux';

// var message = '1';
// var key = CryptoJS.enc.Utf8.parse("c7b54a1gnftv9apa");

// // 加密
// var encrypted = CryptoJS.AES.encrypt(message,key, {
//   mode: CryptoJS.mode.ECB,
// });

// $('#1').text("Encrypted: "+ encrypted.ciphertext);

// // 密文
// // var chiptext = encrypted.ciphertext
// var chiptext = "877ee2bbf035ed00a7c163441ff428c7";
// // 字串內容先進行Ｈex
// var encryptedHexStr = CryptoJS.enc.Hex.parse(chiptext);

// // 需要先轉成Base64才可以解
// var encryptedBase64Str = CryptoJS.enc.Base64.stringify(encryptedHexStr);

// // 解密
// var decrypted = CryptoJS.AES.decrypt(encryptedBase64Str, key, {
//   mode: CryptoJS.mode.ECB,
// });

// $('#2').text("decrypted: "+ decrypted.toString(CryptoJS.enc.Utf8));

const CryptoJS = require('crypto-js');
const subStrToken = str => {
  if (!str) return;
  const key = CryptoJS.enc.Utf8.parse(str.substring(0, 16));
  return key;
};

function useCrypto() {
  const token = 'spVD5wCs2vcMTfO2';
  /**
   * 加密 Encrypt
   */
  const encryptBase64Handle = useCallback((text = '', key = '') => {
    if (!text || !key) throw Error('加密设定错误');
    let encrypted = CryptoJS.AES.encrypt(text, key, {
      mode: CryptoJS.mode.ECB,
    }).toString();
    if (!encrypted) throw Error('加密字串為空');
    return encrypted;
  }, []);
  const encryptHexHandle = useCallback((text = '', key = '') => {
    if (!text || !key) throw Error('加密设定错误');
    let encrypted = CryptoJS.AES.encrypt(text, key, {
      mode: CryptoJS.mode.ECB,
    }).ciphertext.toString();
    if (!encrypted) throw Error('加密字串為空');
    return encrypted;
  }, []);
  const encryptStrHandle = useCallback(
    (text = '', type = 'base64') => {
      try {
        if (!text || !token) throw Error('Handle 加密设定错误');
        const secretKey = subStrToken(token);
        const typeHandle = {
          base64: () => encryptBase64Handle(text, secretKey),
          hex: () => encryptHexHandle(text, secretKey),
        };
        return typeHandle[type] ? typeHandle[type]() : '';
      } catch (error) {
        console.log('encrypt error: ', error);
        throw Error(error?.message || error);
      }
    },
    [encryptBase64Handle, encryptHexHandle, token],
  );

  /**
   * 解密 Decrypt
   */
  const decryptBase64 = useCallback((cipherStr = '', key = '') => {
    if (!cipherStr || !key) throw Error('解密设定错误');
    const decrypted = CryptoJS.AES.decrypt(cipherStr, key, {
      mode: CryptoJS.mode.ECB,
    });
    const str = decrypted.toString(CryptoJS.enc.Utf8);
    if (!str) throw Error('解密字串为空');
    return str;
  }, []);
  const decryptHex = useCallback((cipherStr = '', key = '') => {
    if (!cipherStr || !key) throw Error('解密设定错误');
    // 字串內容先進行 Ｈex > Base64
    const hexStr = CryptoJS.enc.Hex.parse(cipherStr);
    const Base64Str = CryptoJS.enc.Base64.stringify(hexStr);
    const decryptStr = CryptoJS.AES.decrypt(Base64Str, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.NoPadding,
    });
    const str = decryptStr.toString(CryptoJS.enc.Utf8);
    if (!str) throw Error('解密字串为空');
    return str;
  }, []);
  const decryptStrHandle = useCallback(
    (cipherStr = '', type = 'base64') => {
      try {
        const secretKey = subStrToken(token);
        if (!secretKey || !cipherStr) return '';
        const typeHandle = {
          base64: () => decryptBase64(cipherStr, secretKey),
          hex: () => decryptHex(cipherStr, secretKey),
        };
        return typeHandle[type] ? typeHandle[type]() : '';
      } catch (error) {
        console.log('decrypt error: ', error);
        return `讯息解密错误: ${error?.message || ''}(${cipherStr})`;
      }
    },
    [decryptBase64, decryptHex, token],
  );

  return { encryptStrHandle, decryptStrHandle };
}

export default useCrypto;
